const BASE_URL = "https://construction.site-pocket.com";
const USERS = "/api/users";
const USERSTYPES = "/api/users-catalog?type=";
const ONE_USER = "/api/user-catalog";
const PROFILE = "/api/auth/user-profile";
const EDIT_PROFILE = "/api/auth/edit-profile";
const PACKAGES = "/api/packages";
const SUBSCRIPTIONS = "/api/subscriptions";
const TRANSCATIONS = "/api/transactions";
const CATEGORIES = "/api/project-categories";
const PROJECTS = "/api/projects";
const REQUESTS = "/api/request-quotes";
const REQUEST_ID = "/api/request-quote";
const ADD_REQUESTS = "/api/request-quote";
const PORTFOLIOS= "/api/portfolios";
const SCRAPS = "/api/scraps";
const BIDS = "/api/bids";
const SPECIFIC_BID = "/api/bid";
const CHART = "/api/counts";
const ACTIVE_USERS = "/api/active-users";
const COMPLAINS = "/api/complaints";
const USER_REQUEST = "/api/notifications/quote-requested";
const Supplier_Registration = "/api/supplier-registrations"
const DIGITAL_TRANSFORMATION = "/api/digital-transformations"

const REGISTER = '/api/auth/register';
const LOGIN = "/api/auth/login";
const LOGOUT = "/api/auth/logout";
const FORGOT = "/api/password/forget-password";
const OTP = "/api/password/verify-otp";
const UPDATE_PASSWORD = "/api/password/update";
const CHANGE_PASSWORD = "/api/auth/change-password";

const PROJECTS_COUNT = "/api/counts/projects";
const CATEGORIES_COUNT = "/api/counts/category";
const USERS_COUNT = "/api/counts/users";
const SUBSCRIPTIONS_COUNT = "/api/counts/subscription";
const REQUESTS_COUNT = "/api/counts/requests";
const PORTFOLIOS_COUNT = "/api/counts/portfolio";
const SCRAP_COUNT = "/api/counts/scrap";
const BIDS_COUNT = "/api/counts/bid";
const COMPLAINS_COUNT = "/api/counts/complaint";
const TRANSCATIONS_COUNT = "/api/counts/transaction";

const CHAT_ROOM = "/api/chat-room";
const SUPPORT_CHAT = "/api/chat-room-admin";
const NOTIFICATION = "/api/notifications";
const MARK_ALL = "/api/notifications/read";


export {
    BASE_URL,
    LOGIN,
    LOGOUT,
    REGISTER,
    FORGOT,
    OTP,
    UPDATE_PASSWORD,
    CHANGE_PASSWORD,
    USERS,
    ONE_USER,
    PROFILE,
    EDIT_PROFILE,
    PACKAGES,
    SUBSCRIPTIONS,
    TRANSCATIONS,
    CATEGORIES,
    PROJECTS,
    REQUESTS,
    REQUEST_ID,
    ADD_REQUESTS,
    PORTFOLIOS,
    SCRAPS,
    BIDS,
    SPECIFIC_BID,
    CHART,
    USERSTYPES,
    ACTIVE_USERS,
    COMPLAINS,
    USER_REQUEST,
    Supplier_Registration,
    DIGITAL_TRANSFORMATION,

    PROJECTS_COUNT,
    CATEGORIES_COUNT,
    USERS_COUNT,
    SUBSCRIPTIONS_COUNT,
    REQUESTS_COUNT,
    PORTFOLIOS_COUNT,
    SCRAP_COUNT,
    BIDS_COUNT,
    COMPLAINS_COUNT,
    TRANSCATIONS_COUNT,

    CHAT_ROOM,
    SUPPORT_CHAT,
    NOTIFICATION,
    MARK_ALL
}