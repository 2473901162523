import React, { useContext } from "react";
import "../../css/containedbtn.css";
import { LocalContext } from "../../contexts/LocalContext";

export default function ContainedBtn({
  title,
  color,
  disabled,
  width,
  fontWeight,
  fontSize,
  padding,
  margin,
  onClick,
  btnIsDisabled,
}) {
  const { locale, setLocale } = useContext(LocalContext);
  let btnClasses = "";
  if (btnIsDisabled) {
    btnClasses = "disabled";
  } else {
    btnClasses = "contained-btn";
  }

  let btnwidth = "";
  if (locale === "ur") {
    btnwidth = "200px";
  } else if (width) {
    btnwidth = width;
  } else {
    btnwidth = "130px";
  }

  return (
    <div
      style={{
        width: width ? width : "130px",
        margin: margin ? margin : "0 0",
      }}
    >
      <button
        style={{
          padding: padding ? padding : "10px",
          fontSize: fontSize ? fontSize : "16px",
          fontWeight: fontWeight ? fontWeight : "500",
          width: btnwidth,
          color: color ? color : "",
          // backgroundColor: disabled ? 'gray' : ''
        }}
        className={btnClasses}
        onClick={onClick}
        disabled={btnIsDisabled}
      >
        {title}
      </button>
    </div>
  );
}
