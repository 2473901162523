export const getStatesForCountry = (country) => {
    switch (country) {
        case 'Egypt':
            return [
                { name: 'Cairo', labelAr: 'القاهرة', labelEn: 'Cairo' },
                { name: 'Alexandria', labelAr: 'الإسكندرية', labelEn: 'Alexandria' },
                { name: 'Beheira', labelAr: 'البحيرة', labelEn: 'Beheira' },
                { name: 'Faiyum', labelAr: 'الفيوم', labelEn: 'Faiyum' },
                { name: 'Gharbia', labelAr: 'الغربية', labelEn: 'Gharbia' },
                { name: 'Ismailia', labelAr: 'الإسماعيلية', labelEn: 'Ismailia' },
                { name: 'Giza', labelAr: 'الجيزة', labelEn: 'Giza' },
                { name: 'Dakahlia', labelAr: 'الدقهلية', labelEn: 'Dakahlia' },
                { name: 'Damietta', labelAr: 'دمياط', labelEn: 'Damietta' },
                { name: 'Sohag', labelAr: 'سوهاج', labelEn: 'Sohag' },
                { name: 'Suez', labelAr: 'السويس', labelEn: 'Suez' },
                { name: 'Sharqia', labelAr: 'الشرقية', labelEn: 'Sharqia' },
                { name: 'North Sinai', labelAr: 'شمال سيناء', labelEn: 'North Sinai' },
                { name: 'Luxor', labelAr: 'الأقصر', labelEn: 'Luxor' },
                { name: 'Aswan', labelAr: 'أسوان', labelEn: 'Aswan' },
                { name: 'Assiut', labelAr: 'أسيوط', labelEn: 'Assiut' },
                { name: 'Beni Suef', labelAr: 'بني سويف', labelEn: 'Beni Suef' },
                { name: 'Port Said', labelAr: 'بورسعيد', labelEn: 'Port Said' },
                { name: 'South Sinai', labelAr: 'جنوب سيناء', labelEn: 'South Sinai' },
                { name: 'Qalyubia', labelAr: 'القليوبية', labelEn: 'Qalyubia' },
                { name: 'Qena', labelAr: 'قنا', labelEn: 'Qena' },
                { name: 'Kafr El Sheikh', labelAr: 'كفر الشيخ', labelEn: 'Kafr El Sheikh' },
                { name: 'Monufia', labelAr: 'المنوفية', labelEn: 'Monufia' },
                { name: 'Minya', labelAr: 'المنيا', labelEn: 'Minya' },
                { name: 'New Valley', labelAr: 'الوادي الجديد', labelEn: 'New Valley' }
            ];
        case 'Saudi Arabia':
            return [
                // { name: 'Al-Aflaj', labelAr: 'الأفلاج', labelEn: 'Al-Aflaj' },
                // { name: 'Al-Aridhah', labelAr: 'العارضة', labelEn: 'Al-Aridhah' },
                // { name: 'Al-Artaweeiyah', labelAr: 'الارطاوية', labelEn: 'Al-Artaweeiyah' },
                // { name: 'Al-Bukayriyah', labelAr: 'البكيرية', labelEn: 'Al-Bukayriyah' },
                // { name: 'Al-Dawadmi', labelAr: 'الدوادمي', labelEn: 'Al-Dawadmi' },
                // { name: 'Al-Hareeq', labelAr: 'الحريق', labelEn: 'Al-Hareeq' },
                // { name: 'Al-Hinakiya', labelAr: 'الحناكية', labelEn: 'Al-Hinakiya' },
                // { name: 'Al-Hulwah', labelAr: 'الحلوة', labelEn: 'Al-Hulwah' },
                // { name: 'Al-Jafr', labelAr: 'الجفر', labelEn: 'Al-Jafr' },
                // { name: 'Al-Jamoom', labelAr: 'الجموم', labelEn: 'Al-Jamoom' },
                // { name: 'Al-Khabra', labelAr: 'الخبراء', labelEn: 'Al-Khabra' },
                // { name: 'Al-Kharj', labelAr: 'الخرج', labelEn: 'Al-Kharj' },
                // { name: 'Al-Kharma', labelAr: 'الخرمة', labelEn: 'Al-Kharma' },
                // { name: 'Al-Khobar', labelAr: 'الخبر', labelEn: 'Al-Khobar' },
                // { name: 'Al-Lith', labelAr: 'الليث', labelEn: 'Al-Lith' },
                // { name: 'Al-Makhwah', labelAr: 'المخواة', labelEn: 'Al-Makhwah' },
                // { name: 'Al-Mithnab', labelAr: 'المذنب', labelEn: 'Al-Mithnab' },
                // { name: 'Al-Mubarraz', labelAr: 'المبرز', labelEn: 'Al-Mubarraz' },
                // { name: 'Al-Namas', labelAr: 'النماص', labelEn: 'Al-Namas' },
                // { name: 'Al-Omran', labelAr: 'العمران', labelEn: 'Al-Omran' },
                // { name: 'Al-Oyoon', labelAr: 'العيون', labelEn: 'Al-Oyoon' },
                // { name: 'Al-Qatif', labelAr: 'القطيف', labelEn: 'Al-Qatif' },
                // { name: 'Al-Qunfudhah', labelAr: 'القنفذة', labelEn: 'Al-Qunfudhah' },
                // { name: 'Al-Qurayyat', labelAr: 'القريات', labelEn: 'Al-Qurayyat' },
                // { name: 'Al-Rass', labelAr: 'الرس', labelEn: 'Al-Rass' },
                // { name: 'Al-Rayn', labelAr: 'الرين', labelEn: 'Al-Rayn' },
                // { name: 'Al-Rumah', labelAr: 'الرماح', labelEn: 'Al-Rumah' },
                // { name: 'Al-Saih', labelAr: 'السيح', labelEn: 'Al-Saih' },
                // { name: 'Arar', labelAr: 'عرعر', labelEn: 'Arar' },
                // { name: 'Artawiyah', labelAr: 'ارطاوية', labelEn: 'Artawiyah' },
                // { name: 'As-Sarrar', labelAr: 'الصرار', labelEn: 'As-Sarrar' },
                // { name: 'At-Tuwal', labelAr: 'الطوال', labelEn: 'At-Tuwal' },
                // { name: 'Az-Zulfi', labelAr: 'الزلفي', labelEn: 'Az-Zulfi' },
                // { name: 'Badr', labelAr: 'بدر', labelEn: 'Badr' },
                // { name: 'Baha', labelAr: 'الباحة', labelEn: 'Baha' },
                // { name: 'Baljurashi', labelAr: 'بلجرشي', labelEn: 'Baljurashi' },
                // { name: 'Bareq', labelAr: 'بارق', labelEn: 'Bareq' },
                // { name: 'Bisha', labelAr: 'بيشة', labelEn: 'Bisha' },
                // { name: 'Dammam', labelAr: 'الدمام', labelEn: 'Dammam' },
                // { name: 'Dhurma', labelAr: 'ضرما', labelEn: 'Dhurma' },
                // { name: 'Dhahran', labelAr: 'الظهران', labelEn: 'Dhahran' },
                // { name: 'Diriyah', labelAr: 'الدرعية', labelEn: 'Diriyah' },
                // { name: 'Duba', labelAr: 'ضبا', labelEn: 'Duba' },
                // { name: 'Dumat al-Jandal', labelAr: 'دومة الجندل', labelEn: 'Dumat al-Jandal' },
                // { name: 'Hafr Al-Batin', labelAr: 'حفر الباطن', labelEn: 'Hafr Al-Batin' },
                // { name: 'Hawtat Bani Tamim', labelAr: 'حوطة بني تميم', labelEn: 'Hawtat Bani Tamim' },
                // { name: 'Hajrah', labelAr: 'الحجرة', labelEn: 'Hajrah' },
                // { name: 'Hofuf', labelAr: 'الهفوف', labelEn: 'Hofuf' },
                // { name: 'Hotat Bani Tamim', labelAr: 'حوطة بني تميم', labelEn: 'Hotat Bani Tamim' },
                // { name: 'Huraymila', labelAr: 'حريملاء', labelEn: 'Huraymila' },
                // { name: 'Jalajil', labelAr: 'جلاجل', labelEn: 'Jalajil' },
                // { name: 'Jeddah', labelAr: 'جدة', labelEn: 'Jeddah' },
                // { name: 'Jizan', labelAr: 'جازان', labelEn: 'Jizan' },
                // { name: 'Khamis Mushait', labelAr: 'خميس مشيط', labelEn: 'Khamis Mushait' },
                // { name: 'Khulais', labelAr: 'خليص', labelEn: 'Khulais' },
                // { name: 'Khubar', labelAr: 'الخبر', labelEn: 'Khubar' },
                // { name: 'Layla', labelAr: 'ليلى', labelEn: 'Layla' },
                // { name: 'Lulua', labelAr: 'اللؤلؤة', labelEn: 'Lulua' },
                { name: 'Hail', labelAr: 'حائل', labelEn: 'Hail' },
                { name: 'Makkah', labelAr: 'مكة المكرمة', labelEn: 'Makkah' },
                { name: 'Madinah', labelAr: 'المدينة المنورة', labelEn: 'Madinah' },
                { name: 'Al Qassim', labelAr: 'القصيم', labelEn: 'Al Qassim' },
                { name: 'Aseer', labelAr: 'عسير', labelEn: 'Aseer' },
                { name: 'Tabuk', labelAr: 'تبوك', labelEn: 'Tabuk' },
                { name: 'Jazan', labelAr: 'جازان', labelEn: 'Jazan' },
                { name: 'Najran', labelAr: 'نجران', labelEn: 'Najran' },
                { name: 'Al Jowf', labelAr: 'الجوف', labelEn: 'Al Jowf' },
                { name: 'Al Bahah', labelAr: 'الباحة', labelEn: 'Al Bahah' },
                { name: 'Northern Borders', labelAr: 'الحدود الشمالية', labelEn: 'Northern Borders' },
                { name: 'Eastern Province', labelAr: 'المنطقة الشرقية', labelEn: 'Eastern Province' },
                { name: 'Riyadh', labelAr: 'الرياض', labelEn: 'Riyadh' },
                // { name: 'Mahd adh Dhahab', labelAr: 'مهد الذهب', labelEn: 'Mahd adh Dhahab' },
                // { name: 'Muhayil', labelAr: 'محايل', labelEn: 'Muhayil' },
                // { name: 'Muzahmiyya', labelAr: 'المزاحمية', labelEn: 'Muzahmiyya' },
                // { name: 'Najran', labelAr: 'نجران', labelEn: 'Najran' },
                // { name: 'Nairyah', labelAr: 'النعيرية', labelEn: 'Nairyah' },
                // { name: 'Oqlat al-Suqur', labelAr: 'عقلة الصقور', labelEn: 'Oqlat al-Suqur' },
                // { name: 'Rafha', labelAr: 'رفحاء', labelEn: 'Rafha' },
                // { name: 'Ras Tanura', labelAr: 'رأس تنورة', labelEn: 'Ras Tanura' },
                // { name: 'Sakakah', labelAr: 'سكاكا', labelEn: 'Sakakah' },
                // { name: 'Safwa', labelAr: 'صفوى', labelEn: 'Safwa' },
                // { name: 'Shaqraa', labelAr: 'شقراء', labelEn: 'Shaqraa' },
                // { name: 'Shaybah', labelAr: 'الشيبة', labelEn: 'Shaybah' },
                // { name: 'Shuqaiq', labelAr: 'الشقيق', labelEn: 'Shuqaiq' },
                // { name: 'Sihat', labelAr: 'سيهات', labelEn: 'Sihat' },
                // { name: 'Sulayyil', labelAr: 'السليل', labelEn: 'Sulayyil' },
                // { name: 'Tabarjal', labelAr: 'طبرجل', labelEn: 'Tabarjal' },
                // { name: 'Tabuk', labelAr: 'تبوك', labelEn: 'Tabuk' },
                // { name: 'Taif', labelAr: 'الطائف', labelEn: 'Taif' },
                // { name: 'Tanomah', labelAr: 'تنومة', labelEn: 'Tanomah' },
                // { name: 'Tarout', labelAr: 'تاروت', labelEn: 'Tarout' },
                // { name: 'Thadiq', labelAr: 'ثادق', labelEn: 'Thadiq' },
                // { name: 'Thuqbah', labelAr: 'الثقبة', labelEn: 'Thuqbah' },
                // { name: 'Turaif', labelAr: 'طريف', labelEn: 'Turaif' },
                // { name: 'Tayma', labelAr: 'تيماء', labelEn: 'Tayma' },
                // { name: 'Unaizah', labelAr: 'عنيزة', labelEn: 'Unaizah' },
                // { name: 'Uyun', labelAr: 'العيون', labelEn: 'Uyun' },
                // { name: 'Ula', labelAr: 'العلا', labelEn: 'Ula' },
                // { name: 'Umm Lujj', labelAr: 'أملج', labelEn: 'Umm Lujj' },
                // { name: 'Uthmaniya', labelAr: 'عثمانية', labelEn: 'Uthmaniya' },
                // { name: 'Uyayna', labelAr: 'العيينة', labelEn: 'Uyayna' },
                // { name: 'Wadi ad-Dawasir', labelAr: 'وادي الدواسر', labelEn: 'Wadi ad-Dawasir' },
                // { name: 'Yanbu', labelAr: 'ينبع', labelEn: 'Yanbu' },
                // { name: 'Yathrib', labelAr: 'يثرب', labelEn: 'Yathrib' },
                // { name: 'Zahran Al-Janoub', labelAr: 'ظهران الجنوب', labelEn: 'Zahran Al-Janoub' },
            ];
        default:
            return [];
    }
};
