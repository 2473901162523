export const categoryNames = [
    { name_en: "Excavation Works", name_ar: "اعمال حفر", value: "Excavation Works" },
    { name_en: "Skeleton Works", name_ar: "اعمال عظم", value: "Skeleton Works" },
    { name_en: "Soil Testing Works", name_ar: "اعمال فحص تربه", value: "Soil Testing Works" },
    { name_en: "Backfilling Works", name_ar: "اعمال دفان", value: "Backfilling Works" },
    { name_en: "Reinforced Carpentry Works", name_ar: "اعمال نجارة مسلحة", value: "Reinforced Carpentry Works" },
    { name_en: "Blacksmith Works", name_ar: "اعمال حداده", value: "Blacksmith Works" },
    { name_en: "Construction Works", name_ar: "اعمال بناء", value: "Construction Works" },
    { name_en: "Plumbing Works", name_ar: "اعمال سباكة", value: "Plumbing Works" },
    { name_en: "Electrical Works", name_ar: "عمال كهرباء", value: "Electrical Works" },
    { name_en: "Plastering Works", name_ar: "اعمال لياسة", value: "Plastering Works" },
    { name_en: "Insulation Works", name_ar: "اعمال عزل", value: "Insulation Works" },
    { name_en: "Carpentry Works", name_ar: "اعمال نجارة", value: "Carpentry Works" },
    { name_en: "Painting Works", name_ar: "اعمال دهان", value: "Painting Works" },
    { name_en: "Ceramic and Tile Works", name_ar: "أعمال سيراميك و بلاط", value: "Ceramic and Tile Works" },
    { name_en: "Suspended Ceiling and Gypsum Works", name_ar: "اعمال اسقف مستعارة و الجبس", value: "Suspended Ceiling and Gypsum Works" },
    { name_en: "Decoration Works", name_ar: "اعمال ديكور", value: "Decoration Works" },
    { name_en: "Interlock Works", name_ar: "اعمال انترولوك", value: "Interlock Works" },
    { name_en: "Curbstone Works", name_ar: "اعمال بلدورات", value: "Curbstone Works" },
    { name_en: "Asphalt Works", name_ar: "اعمال اسفلت", value: "Asphalt Works" },
    { name_en: "Cement Board Works", name_ar: "اعمال اسمنت بورد", value: "Cement Board Works" },
    { name_en: "Duct Works", name_ar: "اعمال دكت", value: "Duct Works" },
    { name_en: "Fire Alarm Works", name_ar: "اعمال انذرات الحريق", value: "Fire Alarm Works" },
    { name_en: "Communications and Networks Works", name_ar: "اعمال اتصالات و شبكات", value: "Communications and Networks Works" },
    { name_en: "BMS Works", name_ar: "اعمال بي ام اس", value: "BMS Works" },
    { name_en: "Cleaning Works", name_ar: "اعمال نظافه", value: "Cleaning Works" },
    { name_en: "Doors Works", name_ar: "اعمال أبواب", value: "Doors Works" },
    { name_en: "Windows Works", name_ar: "اعمال نوافذ", value: "Windows Works" },
    { name_en: "Aluminum Works", name_ar: "اعمال الالمونيوم", value: "Aluminum Works" },
    { name_en: "Metal Structure Works", name_ar: "اعمال هيكل معدني", value: "Metal Structure Works" },
    { name_en: "Other", name_ar: "أخرى", value: "Other" },
];
